body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #ddd;
  background-color: #1c1c1c;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: #111;
}

.navbar-logo {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  gap: 100px; /* Distribuye espacio entre enlaces */
  flex-grow: 1;
  justify-content: center;
}

.navbar-links a {
  color: #bbb;
  text-decoration: none;
  font-weight: 500;
}

.navbar-links a:hover {
  color: #fff;
}



/*segunda seccion*/
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-image: url('../../assets/images/landing/header.jpg');
  background-size: cover;
  background-position: center;
  height: 80vh;
}

.hero-overlay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  color: #ddd;
}

.hero-content {
  flex: 1;
  max-width: 50%;
  color: #ddd;
  padding-right: 20px;
}

.header-item {
  display: flex;
  align-items: flex-start;
  gap: 10px; /* Espacio entre icono y el grupo de texto */
  margin-bottom: 20px;
}

.icono-header {
  width: 50px; /* Tamaño del icono */
  height: 50px;
  margin-top: 15px;
}

.text-group {
  display: flex;
  flex-direction: column;
}

.text-group h1, .text-group h2 {
  margin: 0;
  font-weight: 700;
  color: #fff;
}

.text-group h1 {
  font-size: 2.5rem;
}

.text-group h2 {
  font-size: 2rem;
}

.text-group p {
  font-size: 1rem;
  color: #bbb;
  margin: 5px 0 0; /* Asegura que el párrafo esté cerca del título */
}

.hero-image {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
}

.hero-image img {
  width: 100%;
  height: auto;
  max-width: 450px;
}



/* Estilos para la segunda sección (Help Section) */

/* HelpSection.css */

.help-section {
  text-align: center;
  padding: 50px 0;
  color: #bbb;
}

.help-section h2 {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}

.help-section h3 {
  font-size: 1.5rem;
  color: #888;
  font-weight: 400;
  margin-bottom: 40px;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
 
}

.help-section-title {
  font-size: 2.2rem; /* Ajusta el tamaño según la necesidad */
  font-weight: 600; /* Ajusta la grueso del texto */
  text-align: center;
  color: #b3b3b3;
  margin-bottom: 20px;
}

/* Ajustes en el contenedor para limitar el ancho */
.help-cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1200px; /* Ajusta el ancho máximo para dar margen */
  margin: 0 auto; /* Centra el contenedor */
  padding: 20px;
}

.help-card {
   background-color: #1a1a1a;
  border: 1px solid #333; /* Borde gris claro para que resalte */
  padding: 20px;
  border-radius: 10px;
  width: 35%; /* Ajusta el ancho para evitar que se extiendan demasiado */
  text-align: center;
}

.help-card h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 15px;
  color: #fff;
}

.help-card p {
  font-size: 0.9rem;
  color: #bbb;
  line-height: 1.6;
}

.card-icon {
  width: 60px;
  height: auto;
  margin-bottom: 20px;
}

/* fin secicon 2 */


/*seccion 3*/
/* Nueva Sección: ¿Por qué elegirnos? */

.container-principal {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  border-radius: 15px;
  border: 1px solid #4d4d4d;
  background-color: #1a1a1a;
  text-align: center;
}

.section-title {
  font-size: 1.1em; /* Ajuste de tamaño de fuente */
  font-weight: bold;
  color: #bfbfbf;
  margin-bottom: 40px;
}

.contenedor-cuadros {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.cuadro {
  flex: 1 1 200px;
  max-width: 250px;
  padding: 20px;
  background-color: transparent; /* Eliminación del fondo */
  border-radius: 10px;
  text-align: center;
  color: #d3d3d3;
  border: none; /* Eliminación de borde */
}

.cuadro img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.cuadro h3 {
  font-size: 0.8em !important; /* Ajusta este valor según el tamaño deseado */
  font-weight: bold !important;
  margin-bottom: 10px !important;
  color: #d3d3d3 !important;
}

.cuadro p {
  font-size: 0.9em;
  line-height: 1.4;
  color: #a6a6a6;
}




/*fin seccion 3*/

/* Estilos para la sección de Contáctanos */
/* Sección de Contacto */
/* Sección de Contacto */
.contact-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 20px;
}

.contact-title {
  font-size: 2.5em; /* Ajuste de tamaño */
  font-weight: bold;
  color: #bfbfbf;
  text-align: left; /* Para alinearlo a la izquierda */
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form select,
.contact-form button {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #2e2e2e;
  color: #d3d3d3;
  border: 1px solid #4d4d4d; /* Añadir borde */
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form select:focus,
.contact-form button:focus {
  outline: none;
  border-color: #bfbfbf;
}

.contact-form button {
  background-color: #1a1a1a;
  color: #d3d3d3;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #333333;
}

/* Estilos para los Radios */
.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #a6a6a6;
}

.radio-group input[type="radio"] {
  accent-color: #bfbfbf;
}

.radio-group label {
  font-size: 0.9em;
}

/* Footer */
/* Estilos del footer */
.footer {
  background-color: #111; /* Ajusta según el color de fondo de tu sitio */
  padding: 20px 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-top {
  display: flex;
  align-items: center;
}

.footer-logo {
  margin-right: 15px;
}

.footer-line {
  flex-grow: 1;
  height: 1px;
  background-color: #a6a6a6;
}

.footer-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.footer-links {
  display: flex;
  gap: 15px;
  font-size: 0.9em;
  color: #a6a6a6;
}
.footer-logo img {
  width: 30px; /* Ajusta el tamaño del icono aquí */
  height: auto;
}

.footer {
  background: none; /* Esto asegura que no haya fondo extra */
}

.footer-container {
  padding: 0; /* Si quieres eliminar cualquier espacio extra */
}
.footer-links a {
  color: #a6a6a6; /* Cambia al gris que usas */
  text-decoration: none;
}

.footer-links a:hover {
  color: #ffffff; /* O el color que prefieras para el hover */
}


/* Input de texto personalizado */
.custom-input {
  background-color: transparent;
  color: #ddd;
  border: 1px solid #4d4d4d;
  border-radius: 8px;
  padding: 8px;
  font-size: 0.9rem;
  transition: border-color 0.3s ease;
}

.custom-input:focus {
  border-color: #888;
  outline: none;
  box-shadow: none;
}

/* Input de archivos personalizado */
/* Estilo para el input de archivo con fondo transparente */
/* Estilos para el botón de archivo */
.custom-file-input::-webkit-file-upload-button {
  background: #333 !important; /* Fondo oscuro para el botón */
  color: #ddd !important;
  border: none !important;
  padding: 4px 8px !important;
  font-size: 0.85rem !important;
  border-radius: 4px !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-file-input::-webkit-file-upload-button:hover {
  background-color: #333 !important; /* Mantener fondo oscuro en hover */
  color: #ddd !important;
}

.custom-file-input::file-selector-button {
  background: #333 !important;
  color: #ddd !important;
  border: none !important;
  padding: 4px 8px !important;
  font-size: 0.85rem !important;
  border-radius: 4px !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-file-input::file-selector-button:hover {
  background-color: #333 !important; /* Mantener fondo oscuro en hover */
  color: #ddd !important;
}




/* Estilos para el menú hamburguesa */
.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle .hamburger {
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.menu-toggle .hamburger::before,
.menu-toggle .hamburger::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.menu-toggle .hamburger::before {
  transform: translateY(-8px);
}

.menu-toggle .hamburger::after {
  transform: translateY(8px);
}

.menu-toggle .hamburger.open {
  background-color: transparent;
}

.menu-toggle .hamburger.open::before {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle .hamburger.open::after {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Ocultar enlaces por defecto */
.navbar-links {
  display: flex;
  gap: 20px;
  list-style: none;
  position: relative;
}

.navbar-links a {
  text-decoration: none;
  color: #bbb;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #fff;
}

.navbar-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #111;
  padding: 20px;
}

.navbar-links.open a {
  margin: 10px 0;
}

.navbar-links a {
  text-align: center;
}

/* Mostrar menú hamburguesa en móviles */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: flex;
  }
}

/* Ajustes generales para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .hero-content {
    max-width: 100%;
    padding: 0;
  }

  .cards-container {
    flex-direction: column;
  }

  .contenedor-cuadros {
    flex-direction: column;
    align-items: center;
  }
}

/* Ajustes para las tarjetas de la sección "Cómo te estamos ayudando" */
@media screen and (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .help-card {
    width: 90%; /* Las tarjetas ocuparán casi todo el ancho */
    margin-bottom: 20px; /* Espacio entre las tarjetas */
  }

  .help-card h4 {
    font-size: 1.2rem; /* Ajusta el tamaño del título */
  }

  .help-card p {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }


/* Ajustes para el formulario de contacto */
.contact-section {
  grid-template-columns: 1fr; /* Unifica las columnas en una */
  padding: 20px;
  text-align: center; /* Centra el texto */
}

.contact-form {
  width: 100%; /* Ocupar todo el ancho */
  max-width: 400px; /* Ancho máximo en pantallas más grandes */
  margin: 0 auto; /* Centrar el formulario */
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form button {
  font-size: 1rem; /* Ajusta el tamaño del texto */
}

.radio-group label {
  font-size: 1rem; /* Ajusta el tamaño de los textos */
}

/* Footer adaptado para móviles */
.footer {
  padding: 20px 10px; /* Ajusta el padding para móviles */
}


}