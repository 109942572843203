body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c; 
}

.auth-left {
  background-color: #1c1c1c; /* Color más oscuro */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-left img {
  width: 120px; /* Ajusta el tamaño del logo si es necesario */
}

.pagination.dark-pagination .page-link {
  background-color: #2a2a2a;
  color: #ffffff;
  border: 1px solid #444;
}

.pagination.dark-pagination .page-item.active .page-link {
  background-color: #444;
  color: #ffffff;
  border-color: #444;
}

.pagination.dark-pagination .page-link:hover {
  background-color: #555;
  color: #ffffff;
}

.footer-custom-bg {
  background-color: #273142; /* Usa el mismo color de fondo que el resto de tu tema */
}



.table thead th {
  color: #b3b3b3;
  background-color: #2e2e2e;
  border-bottom: 1px solid #4d4d4d;
}

.table-bordered tbody td {
  border: 1px solid #4d4d4d;
}

.btn-dark {
  background-color: #333;
  color: #ddd;
}

.btn-dark:hover {
  background-color: #555;
  color: #fff;
}




.form-error {
  color: red;
  margin-top: 5px;
}

.form-success {
  color: green;
  margin-top: 5px;
}

/*radios*/
.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-group label {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #ffffff; /* Color del texto */
  margin-bottom: 10px;
}

.radio-group input[type="radio"] {
  margin-right: 10px;
  width: 18px !important;
  height: 18px !important;
  accent-color: #ffffff !important;
  appearance: auto !important;
}

/*nuevos estilos paea nav */


/* Navbar de escritorio */
/* General Navbar */
.navbar-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-2);
  padding: 10px 20px;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: #bbb;
  font-size: 1rem;
}

.navbar-links a:hover {
  color: #fff;
}

/* Usuario y dropdown */
.navbar-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-dropdown {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #bbb;
}

.user-dropdown .user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-dropdown .user-name {
  margin-left: 5px;
  font-size: 1rem;
}

.dropdown-menu {
  background-color: var(--dark-3);
  border-radius: 8px;
  padding: 10px;
  text-align: left;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  color: #bbb;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--dark-1);
  color: #fff;
}




/* Navbar para móviles */

.navbar-container {
  width: 100%;
  background-color: var(--dark-1);
  display: flex;
  flex-direction: column;
}

/* Estilos para el navbar de escritorio */
.navbar-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-left a {
  margin: 0 15px;
  text-decoration: none;
  color: var(--dark-3);
}

.navbar-left a:hover {
  color: var(--lila-50);
}

.navbar-right {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: var(--dark-1);
  border: 1px solid var(--dark-3);
  border-radius: 5px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}






/* Menú móvil */
/* Estilo general */
.navbar-header {
  background-color: #2e2e2e;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Menú de escritorio */
.navbar-links a {
  text-decoration: none;
  color: #bbb;
  font-size: 1rem;
}

.navbar-links a:hover {
  color: #fff;
}

/* Botón hamburguesa (solo para móvil) */
.hamburger-icon {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

/* Menú móvil */
.mobile-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--dark-1);
  padding: 10px 0;
}

.mobile-menu a {
  color: #bbb;
  text-decoration: none;
  padding: 10px 20px;
}

.mobile-menu a:hover {
  color: #fff;
}

.mobile-menu .btn {
  margin: 0 auto;
}

/* Responsividad */
@media (min-width: 992px) {
  .hamburger-icon {
    display: none;
  }

  .mobile-menu {
    display: none;
  }

  .navbar-links {
    display: flex;
  }
}

/* Ocultar menú por defecto en escritorio */
.navbar-links {
  display: none;
}



/**/
/* Navbar general para admin */
.navbar-admin {
  background-color: #1c1c1c;
  color: #fff;
  padding: 10px 20px;
}

.navbar-admin .nav-link {
  color: #ccc;
  text-decoration: none;
  font-weight: 500;
}

.navbar-admin .nav-link:hover {
  color: #fff;
}

.navbar-admin .admin-icon {
  font-size: 24px;
  color: #ccc;
}

.navbar-admin .dropdown-menu {
  background-color: #1c1c1c;
  border: none;
}
/* Navbar general para admin */

